import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../context/FetchContext";
import { ChStStatusContext } from "../../App/App";
import ChargingStationForm from "./ChargingStationForm";
import ChargingStationCongigurationForm from "./ChargingStationConfigurationForm";
import { Grid } from "@mui/material";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import { CommandList } from "./CommandList";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import AddIcon from "@material-ui/icons/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Popup from "../../components/Popup";
import Loader from "../../components/Loader";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import Confirm from "./Confirm";
import { Paper, Table, TableBody, TableCell, TableContainer, Tooltip, Toolbar, Typography, TableHead, TableRow } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ExpandTable } from "../../components/controls/ExpandTable";
import { makeStyles } from "@material-ui/core";
import FetchConnectors from "./FetchConnectors";
import { socketFetch } from "../../services/socket";
import { socketFetchHttp } from "../../services/socketHttp";
import Filter from "./Filter";
import { DateTime } from "luxon";
import ExportData from "./ExportData";
import DescriptionIcon from "@mui/icons-material/Description";
import NotesForm from "./NotesForm";
import { useParams,useHistory } from 'react-router-dom';
import { ArrowBack } from "@material-ui/icons";
import ChargingStationFooter from "./ChargingStationFooter";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  serviceHistoryIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  breadcrumb:{
    position: 'absolute',
    marginTop: '16px',
    right: '500px',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  filterIcon: {
    position: "absolute",
    marginTop: "25px",
    right: "120px",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  backicon: {
    position: 'absolute',
    marginTop: '25px',
    right: '245px',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  searchIcon: {
    position: "absolute",
    marginTop: "25px",
    right: "160px",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },

  exportIcon: {
    position: "absolute",
    marginTop: "25px",
    right: "200px",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  pageTitle: {
    //paddingLeft:theme.spacing(1),
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
}));

const ShowChargers = () => {
  const history = useHistory();
  const fetchContext = useContext(FetchContext);
  const { flState, setFlState } = useContext(ChStStatusContext);
  const currentPath = window.location.href;
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const [configurationPopup, setConfigurationPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [showFilter, setShowFilter] = useState(true);
  const [showSearch, setShowSearch] = useState(true);
  const [noteId, setNoteId] = useState(0);
  const [openNotes, setOpenNotes] = useState(false);
  const { sitename } = useParams();
  const handleNotesView = () => {
    setOpenNotes(true);
  };

  //const [ filterStatusParam, setFilterStatusParam ] = useState(filterStatus !== undefined && filterStatus !== 'fsn' ? filterStatus : null);
  //const [ filterStatusParam, setFilterStatusParam ] = useState(filterStatus ? filterStatus : 'fsn');

  //const [ confirmOpen, setConfirmOpen ] = useState(false);
  //const dateStr = DateTime.now();
  const [showFilterSearch, setShowFilterSearch] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);


  const headCells = [
    { id: "", label: "" },
    { id: "name", label: "Charging Station" },
    { id: "connectors_status", label: "Status" },
    { id: "ocpp_cbid", label: "OCPP CBID" },
    { id: "site.name", label: "Site" },
    { id: "site_group", label: "Site Account" },
    //{ id: 'actions', label: '', disableSorting: true }
  ];

  if (!fetchContext.isView) {
    headCells.push({ id: "actions", label: "", disableSorting: true });
  }

  const [filterObject, setFilterObject] = useState({
    site_id: null,
    site_group_id: null,
    ocpp_cbid: null,
    payment_interface: null,
    connector_status: null,
    manufacturer_id: null,
    search_keyword: null,
    is_search: false,
    charger_type: null,
    export: false,
  });

  const { TblContainer, TblHead } = useTable(headCells);

  const getAll = (fetchContext, page, filterObject, flState) => {
    if (flState !== undefined && flState !== "fsn") {
      filterObject.connector_status = flState;
    }
    fetchContext.authAxios
      .post(`get-filter-charging-stations?page=${page}`,{ export: false ,search_keyword: sitename},filterObject)
      .then(({ data }) => {
        if (data?.data) {
          setRecords(data.data);
          setTotalRecord(data.totalItems);
        }
        if (data.totalItems && data.totalItems > 20) {
          let count = Math.ceil(data.totalItems / 20);
          setCounts(count);
        } else {
          setCounts(1);
        }
        //}
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (flState === undefined) {
      setFlState("fsn");
    }

    getAll(fetchContext, page, filterObject, flState);
  }, [fetchContext, page, filterObject, flState, setFlState]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  const handleSubmit = (values) => {
    fetchContext.authAxios
      .post("/charging-stations", values)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page, filterObject);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors) {
            let msg = "";
            if (data.errors.name) {
              msg = data.errors.name[0];
            }
            if (data.errors.ocpp_cbid) {
              msg = data.errors.ocpp_cbid[0];
            }
            setNotify({
              isOpen: true,
              message: `Charging Station can't be created as: ${msg}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Charging Station can't be created as: ${err}`,
          type: "error",
        });
      });
  };

  const handleEdit = (id, values) => {
    fetchContext.authAxios
      .put(`/charging-stations/${id}`, values)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page, filterObject);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors) {
            let msg = "";
            if (data.errors.name) {
              msg = data.errors.name[0];
            }
            if (data.errors.ocpp_cbid) {
              msg = data.errors.ocpp_cbid[0];
            }
            setNotify({
              isOpen: true,
              message: `Charging Station can't be edited as: ${msg}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Charging Station can't be edited as: ${err}`,
          type: "error",
        });
      });
  };

  const handleConfigurationsEdit = (id, values) => {
    if (values) {
      if (currentPath.includes("https://")) {
        socketFetch
          .post(`/changeconfiguration`, {
            ocpp_cbid: id,
            configurationKey: values,
          })
          .then(({ data }) => {
            if (data) {
              setNotify({
                isOpen: true,
                message: "Submitted Successfully",
                type: "success",
              });
            }
          })
          .catch((err) => {
            setNotify({
              isOpen: true,
              message: `Configurations can't be edited as: ${err}`,
              type: "error",
            });
          });
      } else {
        socketFetchHttp
          .post(`/changeconfiguration`, {
            ocpp_cbid: id,
            configurationKey: values,
          })
          .then(({ data }) => {
            if (data) {
              setNotify({
                isOpen: true,
                message: "Submitted Successfully",
                type: "success",
              });
            }
          })
          .catch((err) => {
            setNotify({
              isOpen: true,
              message: `Configurations can't be edited as: ${err}`,
              type: "error",
            });
          });
      }
    } else {
      setNotify({
        isOpen: true,
        message: `Configurations can't be edited as: without any value change`,
        type: "error",
      });
    }
  };

  const handleDelete = (id) => {
    fetchContext.authAxios
      .delete(`/charging-stations/${id}`)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page, filterObject);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        } else {
          if (data.data) {
            setNotify({
              isOpen: true,
              message: `Charging Station can't be deleted as: ${data.data}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Charging Station can't be deleted as: ${err}`,
          type: "error",
        });
      });
  };

  const addOrEdit = (ocpp_cbid, chargingStation, resetForm) => {
    if (chargingStation.id === 0) handleSubmit(chargingStation);
    else if (configurationPopup) {
      handleConfigurationsEdit(ocpp_cbid, chargingStation);
    } else {
      handleEdit(chargingStation.id, chargingStation);
    }

    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setConfigurationPopup(false);
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
    setConfigurationPopup(false);
  };

  const openInPopupForConfiguration = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
    setConfigurationPopup(true);
  };

  const closeForm = () => {
    setOpenPopup(false);
  };

  const onDelete = (id) => {
    handleDelete(id);
  };

  const onShowHideFilter = () => {
    setShowFilter((toggle) => !toggle);
    setShowSearch(true);

    if (!showFilter || !showSearch) {
      setLoaded(false);
      setFilterObject({
        site_id: null,
        site_group_id: null,
        ocpp_cbid: null,
        payment_interface: null,
        connector_status: null,
        manufacturer_id: null,
        search_keyword: null,
        is_search: false,
        charger_type: null,
        export: false,
      });
    }
    setFlState("fsn");
  };

  const onShowHideSearchCancel = () => {
    setShowFilter(true);
    setShowSearch(true);

    if (!showFilter || !showSearch) {
      setLoaded(false);
      setFilterObject({
        site_id: null,
        site_group_id: null,
        ocpp_cbid: null,
        payment_interface: null,
        connector_status: null,
        manufacturer_id: null,
        search_keyword: null,
        charger_type: null,
        is_search: false,
        export: false,
      });
    }
    setFlState("fsn");
  };

  const onFilterSubmit = (values) => {
    setPage(1);
    setLoaded(false);
    setFilterObject({
      site_id: values.site_id !== "None" ? values.site_id : null,
      site_group_id: values.site_group_id !== "None" ? values.site_group_id : null,
      ocpp_cbid: values.ocpp_cbid,
      payment_interface: values.payment_interface,
      connector_status: values.connector_status !== "None" ? values.connector_status : null,
      manufacturer_id: values.manufacturer_id !== "None" ? values.manufacturer_id : null,
      search_keyword: values.search_keyword,
      charger_type: values.charger_type,
      is_search: !showSearch,
      export: false,
    });
  };

  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);
    setOpenPopup(false);
  };

  const onShowHideSearch = () => {
    setShowSearch(!showSearch);
    setShowFilter(true);
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Charging Stations
            </Typography>
          </div>
          <div className={classes.backicon}>
            <Tooltip title="Browse Back">
              <ArrowBack onClick={() => history.goBack()} />
            </Tooltip>
          </div>
          <div className={classes.exportIcon}>
            <ExportData filterObject={filterObject} />
          </div>
        
          {fetchContext.isAdmin && (
            <div className={classes.searchIcon} onClick={onShowHideSearch}>
              {showSearch ? (
                <Tooltip title="Search">
                  <SearchIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Remove Search">
                  <SearchOffIcon />
                </Tooltip>
              )}
            </div>
          )}
          <div className={classes.filterIcon} onClick={onShowHideFilter}>
            {showFilter ? (
              <Tooltip title="Filter">
                <FilterAltIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Remove Filter">
                <FilterAltOffIcon />
              </Tooltip>
            )}
          </div>
          {!fetchContext.isView && (
            <Controls.Button
              style={{ color: "#a31112", border: "1px solid #a31112" }}
              text="Add New"
              variant="outlined"
              //color="secondary"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          )}
        </Toolbar>
        {!showFilter && <Filter showSearch={showSearch} onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideFilter} />}
        {!showSearch && <Filter showSearch={showSearch} onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideSearchCancel} />}
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
            //shape="rounded/circular"
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records?.map((item, index) => (
                <ExpandTable
                  expandedContent={
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Charger Type</TableCell>
                            <TableCell>Charger Output</TableCell>
                            <TableCell>Installed At</TableCell>
                            <TableCell>Commissioned At</TableCell>
                            <TableCell>Owner</TableCell>
                            <TableCell>Manufacturer</TableCell>
                            <TableCell>Serial Number</TableCell>
                            {(fetchContext.isAdmin || fetchContext.isTechnician) && <TableCell>Service History</TableCell>}{" "}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{item.charger_type}</TableCell>
                            <TableCell>{item.charger_output}</TableCell>
                            <TableCell>{item.installed_at && DateTime.fromSQL(item.installed_at).toFormat("MM-dd-yyyyy")}</TableCell>
                            <TableCell>{item.commissioned_at && DateTime.fromSQL(item.commissioned_at).toFormat("MM-dd-yyyyy")}</TableCell>
                            <TableCell>{item.owner}</TableCell>
                            <TableCell>{item.manufacturer && item.manufacturer.name}</TableCell>
                            <TableCell>{item.serial_number}</TableCell>
                            {(fetchContext.isAdmin || fetchContext.isTechnician) && (
                              <TableCell>
                                <div
                                  className={classes.serviceHistoryIcon}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setNoteId(item);
                                    handleNotesView();
                                  }}
                                >
                                  <Tooltip title="Service History">
                                    <DescriptionIcon />
                                  </Tooltip>
                                </div>
                              </TableCell>
                            )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                  key={index}
                >
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.connectors && <FetchConnectors charging_station_id={item.id} charging_station={item.name} />}</TableCell>
                  <TableCell>{item.ocpp_cbid}</TableCell>
                  <TableCell>{item.site.name}</TableCell>
                  <TableCell>{item.site.site_group.name}</TableCell>
                  {!fetchContext.isView && (
                    <TableCell class="text-nowrap">
                      <CommandList
                        color="primary"
                        chargingStation={item}
                        handleConfiguration={() => {
                          openInPopupForConfiguration(item);
                        }}
                      />
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          openInPopup(item);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Confirm
                        id={item.id}
                        title={"Are you sure to delete this record?"}
                        subTitle={"You can't undo this operation"}
                        onConfirmClick={() => onDelete(item.id)}
                      />
                    </TableCell>
                  )}
                </ExpandTable>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
        </TblContainer>
        <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
            //shape="rounded/circular"
          />
        </Stack>
        <ChargingStationFooter />
        {/* <div class="col-md-12 alignright">
          {" "}
          <span class="indicatordot">
            <span class="greendot"></span> Available
          </span>{" "}
          <span class="indicatordot">
            <span class="yellowdot"></span> Charging
          </span>{" "}
          <span class="indicatordot">
            <span class="reddot"></span> Faulty
          </span>{" "}
          <span class="indicatordot">
            <span class="grydot"></span> Unavailable
          </span>{" "}
          <span class="indicatordot">
            <span class="oragne2dot"></span> Suspended EVSE
          </span>{" "}
          <span class="indicatordot">
            <span class="browndot"></span> Suspended EV
          </span>{" "}
          <span class="indicatordot">
            <span class="bluedot"></span> Preparing
          </span>{" "}
          <span class="indicatordot">
            <span class="blackdot"></span> Reserved
          </span>{" "}
          <span class="indicatordot">
            <span class="green2dot"></span> Finishing
          </span>{" "}
          <span class="indicatordot">
            <span class="green2dot"></span> Unknown
          </span>{" "}
        </div> */}
      </Paper>
      <Popup title={configurationPopup ? `Configurations: (${recordForEdit.ocpp_cbid})` : "Charging Station"} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {configurationPopup ? (
          <ChargingStationCongigurationForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} closeForm={closeForm} />
        ) : (
          <ChargingStationForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} closeForm={closeForm} />
        )}
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <Popup title="History and Comments" openPopup={openNotes} setOpenPopup={setOpenNotes}>
        <NotesForm chargerId={noteId} closeForm={openNotes} setFormClose={setOpenNotes} />
      </Popup>
    </>
  );
};

export default ShowChargers;