import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../../context/FetchContext";
import useTable from "../../../components/useTable";
//import Controls from "../../components/controls/Controls";
// import AddIcon from '@material-ui/icons/Add';
// import Popup from "../../components/Popup";
// import { MoreHorizRounded } from '@material-ui/icons';
// import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../components/Loader";
import ExportDataRevenueReport from "./ExportDataRevenueReport";
import ExportDataByRevenueReportNext from "./ExportDataByRevenueReportNext";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { Paper, TableBody, TableCell, Toolbar, Typography, Tooltip, TableRow } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
//import { ExpandTable } from '../../components/controls/ExpandTable';
import { Link, makeStyles } from "@material-ui/core";
//import MonthRangeSelect  from './MonthRangeSelect';
import { ArrowBack } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
//import { DateTime } from 'luxon';
import { InfoOutlined } from "@material-ui/icons";
import { useParams, useHistory } from "react-router-dom";
import { TYPE_1, TYPE_2, TYPE_3 } from "./RevenueType";
import { useLocation } from "react-router-dom";
// import SessionStatus from './SessionStatus';
//import ActiveSessionsValues from './ActiveSessionsValues';
//import SessionsPopUp from './SessionsPopUp';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    //width: '75%'
  },
  filterIcon: {
    position: "absolute",
    right: "50px",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  exportIcon: {
    position: "absolute",
    right: "10px",
  },
  pageTitle: {
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  pageTotalText: {
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "220px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    fontWeight: "bold",
  },
  boldtext: {
    fontWeight: "bold",
    // color:"#000000"
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
}));

const RevenueReportNext = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const history = useHistory();
  //const [recordForEdit, setRecordForEdit] = useState(null)
  const [loaded, setLoaded] = useState(false);
  //const dateStr = DateTime.now();
  //const selectedMonth = dateStr.toFormat(`01-${'MM-yyyy'}`);
  const [page, setPage] = useState(1);
  //const [byMonth, setByMonth] = useState(selectedMonth);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  const [totalRevenue, setTotalRevenue] = useState(0);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  //const [openPopup, setOpenPopup] = useState(false)
  //const [transactionId, setTransactionId] = useState(0)
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);

  const { id } = useParams();
  const { bymonth } = useParams();
   const location = useLocation();

   // Parse query parameters from the location.search string
   const queryParams = new URLSearchParams(location.search);
   const amount = queryParams.get("amount");

  
  let splitted_param = window?.location?.pathname.split("/")[1];

  const headCells = [
    { id: "", label: "" },
    { id: "site_name", label: "Site Name" },
    { id: "charging_station", label: "Charging Station" },
    { id: "energy", label: "Energy (kWH)" },
    // { id: "charging_duration", label: "Average Charging Duration (Minutes)" },
    { id: "transactions", label: "Number of Sessions" },
    { id: "net_amount", label: "Net Amount (USD)" },
    { id: "parkling_fees", label: "Parking Fee (USD)" },
    { id: "tax", label: "Tax (USD)" },
    { id: "amount", label: "Amount (USD)" },
    { id: "processing_fee", label: "Processing Fee (USD)" },
    { id: "licensing_fee", label: "Licensing Fee (USD)" },
    // { id: "fixed_fee", label: "Fixed Fee (USD)" },
    // { id: "flat_deduction", label: "Flat Deduction Per Session(USD)" },
    { id: "final_amount", label: "Final Amount (USD)" },
    { id: "site_payout", label: "Site Payout (USD)" },
    { id: "", label: "" },
    // { id: "", label: "" },
  ];

  const {
    TblContainer,
    TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page, siteId, byMonth, filterDate, isFilter) => {
    // {
    // "site_id":siteId,
    // "date":byMonth,
    // 'export':false
    // }

    let finalPayload = isFilter
      ? {
          site_id: siteId,
          date: null,
          export: false,
          range: isFilter,
          to_date: filterDate?.to_date,
          from_date: filterDate?.from_date,
        }
      : {
          site_id: siteId,
          date: byMonth,
          export: false,
          range: isFilter,
        };

    try {
      const { data } = await fetchContext.authAxios.post(`/get-report-group-by-charging-station?page=${page}`, finalPayload);
      //let res = Object.values(data.data);
      setRecords(data.data);
      setTotalRecord(data.totalItems);
      setTotalRevenue(data?.total_amount);
      if (data.totalItems && data.totalItems > 20) {
        let count = Math.ceil(data.totalItems / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }
      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    if (bymonth.includes("to")) {
      // let datePattern = /\d{2}-\d{2}-\d{4}/g;
      let filterString = bymonth.split("to");
      if (filterString?.length === 2) {
        setToDate(filterString[1]);
        setFromDate(filterString[0]);
        let dates = {
          to_date: filterString[1],
          from_date: filterString[0],
        };
        setIsFilter(true);
        getAll(fetchContext, page, id, bymonth, dates, true);
      }
    } else {
      setIsFilter(false);
      getAll(fetchContext, page, id, bymonth, null, false);
    }
  }, [fetchContext, page, id, bymonth]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  const formatNumber = (num) => {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
  };

  const sitePayout = (item, licensing_fee, final_amount, processing_fee) => {
    if (final_amount < 0) {
      return 0;
    }
    let fees = item?.connector?.charging_stations?.site?.processing_fee;
    if (fees?.length > 0 && fees[0]?.agreement_type) {
      switch (fees[0]?.agreement_type) {
        case TYPE_1:
          return licensing_fee;
        case TYPE_2:
          return final_amount;
        case TYPE_3:
          return item.amount - item?.tax_amount - processing_fee;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  const agreement_type_class = (item) => {
     // let fees = item?.connector?.charging_stations?.site?.processing_fee;
    let agreement_type = item?.processingFee
    if (agreement_type) {
      switch (agreement_type?.agreement_type) {
        case TYPE_1:
          return { color: "#FF0000" };
        case TYPE_2:
          return { color: "#000000" };
        case TYPE_3:
          return { color: "#CC5500" };
        default:
          return {};
      }
    } else {
      return {};
    }
  };

  // const openInPopup = item => {
  //     setTransactionId(item)
  //     setOpenPopup(true)
  // }
  // const changeSelectOptionHandler = (event) => {
  //   let report_month = event.target.value;
  //   setLoaded(false);
  //   setByMonth(report_month);
  //   //console.log('report_month', report_month);
  //   //setSelected(event.target.value);
  // };
  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Revenue Report
            </Typography>
          </div>
          <div className={classes.pageTotalText}>
            (Total Revenue Amount: ${" "}
            {<span className={classes.boldtext}>{amount}</span>})
          </div>
          <div className={classes.filterIcon}>
            <Tooltip title="Browse Back">
              <ArrowBack onClick={() => history.goBack()} />
            </Tooltip>
          </div>
          <div className={classes.exportIcon}>
            <ExportDataByRevenueReportNext
              siteId={id}
              byMonth={bymonth}
              filterDate={{
                to_date: toDate,
                from_date: fromDate,
                range: isFilter,
              }}
            />
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item, index) => {
                // let processing_fee = item?.connector?.charging_stations?.site?.processing_fee?.reduce((acc, current) => {
                //   if (current?.processing_fee_type === "percentage") {
                //     return acc + (item?.amount - item?.tax_amount) * (current?.processing_fee / 100);
                //   } else {
                //     return acc + current?.processing_fee * item?.total;
                //   }
                // }, 0);
                // // let fixed_fee = item?.connector?.charging_stations?.site?.processing_fee?.reduce((acc, current) => acc + current?.fixed_fee_per_month, 0).toFixed(2);
                // let licensing_fee = item?.connector?.charging_stations?.site?.processing_fee?.reduce(
                //   (acc, current) => acc + current?.licensing_fee_energy * Number(item?.energy),
                //   0
                // );
                // // let flat_deduction = (process.env.REACT_APP_FLAT_DEDUCTION_PER_SESSION * item?.total).toFixed(2);
                // let final_amount = item?.amount - item?.tax_amount - processing_fee - licensing_fee;
                let site_payout = item.processingFee.site_payout;
                let processing_fee = item.processingFee.processing_fee;
                let licensing_fee = item.processingFee.licensing_fee;
                let final_amount = item.processingFee.final_amount;
                return (
                  <TableRow key={index}>
                    <TableCell />
                    <TableCell style={agreement_type_class(item)}>
                      {item.site_name}
                    </TableCell>
                    <TableCell>{item.charging_station_name}</TableCell>
                    <TableCell>
                      {item.energy ? Number(item.energy).toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell>{item.total}</TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item?.amount
                        ? formatNumber(item?.amount - item?.tax_amount)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item?.parking_amount
                        ? formatNumber(item?.parking_amount)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item?.tax_amount
                        ? formatNumber(item?.tax_amount)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item?.amount
                        ? formatNumber(item?.amount)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : processing_fee
                        ? processing_fee.toFixed(2)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      { licensing_fee
                        ? licensing_fee.toFixed(2)
                        : "0.00"}
                    </TableCell>
                    {/* <TableCell>{fixed_fee}</TableCell>
                    <TableCell>{flat_deduction}</TableCell> */}
                    <TableCell>
                      { final_amount
                        ? final_amount.toFixed(2)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : site_payout
                        ? site_payout.toFixed(2)
                        : "0.00"}
                    </TableCell>
                    {/* <TableCell>{item?.amount <= 0 ? "0.00" : sitePayout(item, licensing_fee, final_amount, processing_fee).toFixed(2)}</TableCell> */}
                    {/* {<TableCell>
                    <Tooltip title="View More">
                      <Link
                        component={RouterLink}
                        to={`/sites-details/${item.site_id}/${item.charging_station_id}/${toDate && fromDate ? `${fromDate}to${toDate}` : bymonth}`}
                      >
                        <InfoOutlined />
                      </Link>
                    </Tooltip>
                  </TableCell>} */}
                    {/* <TableCell>
                      <ExportDataRevenueReport
                        siteId={item.site_id}
                        csId={item.charging_station_id}
                        byMonth={bymonth}
                        isFilter={isFilter}
                        filterDate={{ to_date: toDate, from_date: fromDate }}
                      />
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
            //shape="rounded/circular"
          />
        </Stack> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Typography spacing={2} style={{ color: "#8a0a0b" }} component="div">
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default RevenueReportNext;