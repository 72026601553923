import {
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../components/useForm";
import useTable from "../../components/useTable";
import { FetchContext } from "../../context/FetchContext";
import { socketFetch } from "../../services/socket";
import Confirm from "../ChargingStations/Confirm";
import SitesSelect from "../Sites/SitesSelect";
import ChargingStationSelect from "./ChargingStationsSelect";
import SiteEVSERadioGroup from "./SiteEVSERadioGroup";
import { useIsDesktop } from "../../context/DisplayContext";
import { Pagination, Stack } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
}));

function formatTimestampToISO(timestampString) {
  try {
    const parsedDate = DateTime.fromJSDate(new Date(timestampString));
    const isoDate = parsedDate.toISO();
    return isoDate;
  } catch (error) {
    console.error("Error parsing date:", error.message);
    return null;
  }
}

const LoadSharingSingleStack = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const { isDesktop } = useIsDesktop();
  const [siteEvse, setSiteEvse] = useState("2");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loaded, setLoaded] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [popup, setpopup] = useState(false);

  const [dateCompareError, setDateCompareError] = useState(false);
  const [startdateCompareError, setstartdateCompareError] = useState(false);
  const [timeCompareError, setTimeCompareError] = useState(false);
  const [singleloaddata, setsingleloaddata] = useState();
  const [page, setPage] = useState(1);

  const [totalRecord, setTotalRecord] = useState(0);
  const [counts, setCounts] = useState(1);
  const [payload, setPayload] = useState({});
  const [filterObject, setFilterObject] = useState({
    charger_id: null,
  });
  const [connector, setconnector] = useState(0);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (connector === 0) {
      setOptions([]);
    }
    if (connector === 1) {
      setOptions([{ id: 1, name: 1 }]);
    } else if (connector === 2) {
      setOptions([
        { id: 1, name: 1 },
        { id: 2, name: 2 },
      ]);
    } else if (connector === 3) {
      setOptions([
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
      ]);
    } else if (connector === 4) {
      setOptions([
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
      ]);
    }
  }, [connector]);

  const abortController = useRef(new AbortController());

  let headCells = [
    { id: "charging_profile_id", label: "Charging Profile ID" },
    { id: "charger_id", label: "Charger ID" },
    { id: "charging_schedule_period", label: "Charging Schedule Period" },
  ];

  if (!fetchContext.isView) {
    headCells.push({ id: "actions", label: "", disableSorting: true });
  }

  const getAllProfiles = async (fetchContext, page, filterObject) => {
    fetchContext.authAxios
      .post(`/get-charging-profile?page=${page}`, filterObject)
      .then(({ data }) => {
        setLoaded(true);
        setProfiles(data?.data);
        setTotalRecord(data.totalItems);
        if (data.totalItems && data.totalItems > 20) {
          let count = Math.ceil(data.totalItems / 20);
          setCounts(count);
        } else {
          setCounts(1);
        }
      });
  };

  const { TblContainer, TblHead } = useTable(headCells);

  const initialFValues = {
    start_schedule: "",
  };

  const validate = async (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  useEffect(() => {
    getAllProfiles(fetchContext, page, filterObject);
  }, [page, filterObject]);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const getconnector = async (id) => {
    fetchContext.authAxios
      .post(`/get-number-of-connectors`, { id: id })
      .then(({ data }) => {
        if (data?.data > 0) {
          setconnector(data?.data);
          if (data?.data === 0) {
            setOptions([]);
          } else if (data?.data === 1) {
            setOptions([{ id: 1, name: 1 }]);
          } else if (data?.data === 2) {
            setOptions([
              { id: 1, name: 1 },
              { id: 2, name: 2 },
            ]);
          } else if (data?.data === 3) {
            setOptions([
              { id: 1, name: 1 },
              { id: 2, name: 2 },
              { id: 3, name: 3 },
            ]);
          } else if (data?.data === 4) {
            setOptions([
              { id: 1, name: 1 },
              { id: 2, name: 2 },
              { id: 3, name: 3 },
              { id: 4, name: 4 },
            ]);
          }
        }
      });
  };
  useEffect(() => {
    const matchedProfiles = profiles.filter(
      (profile) => profile.charger_id === values?.charger_id?.id
    );
    if (matchedProfiles?.length > 0) {
      setpopup(true);
      setsingleloaddata(matchedProfiles[0]);
    } else {
      setpopup(false);
      setsingleloaddata();
    }
  }, [profiles, values?.charger_id]);

  useEffect(() => {
    getconnector(values?.charger_id?.charger_id_new);
  }, [values?.charger_id?.charger_id_new]);

  useEffect(() => {
    setFilterObject({ charger_id: values?.charger_id?.id || null });
  }, [values?.charger_id]);

  useEffect(() => {
    if (filterObject) {
      getAllProfiles(fetchContext, page, filterObject);
    }
  }, [filterObject]);

  const setChargingProfile = async (payload) => {
    if (!startdateCompareError && !dateCompareError) {
      if (!popup) {
        const path = "/setChargingProfileSingleStack";
        const updated_payload = {
          connector_id: payload?.connector_id
            ? payload.connector_id
            : options[0]?.id,
          charger_id: payload?.charger_id,
          start_date: `${payload?.start_date.split("T")[0]}T${
            payload?.start_time.split("T")[1].split(".")[0]
          }.000Z`,
          end_date: `${payload?.end_date.split("T")[0]}T${
            payload?.end_time.split("T")[1].split(".")[0]
          }.000Z`,
          start_time: `${payload?.start_time?.split(".")[0]}.000Z`,
          end_time: `${payload?.end_time?.split(".")[0]}.000Z`,
          limit: payload?.limit,
          charging_rate_unit: payload?.charging_rate_unit,
        };
        try {
          const { data } = await socketFetch.post(path, updated_payload);
          if (data?.message) {
            setLoaded(false);
            // getAllProfiles(fetchContext, page, filterObject);
            setNotify({
              isOpen: true,
              message: data.message,
              type: "success",
            });
            setOptions([]);
            setValues({
              connector_id: null,
              charger_id: null,
              start_date: null,
              end_date: null,
              start_time: null,
              end_time: null,
              limit: 0,
              charging_rate_unit: "A",
            });
            setFilterObject({ charger_id: null });
            // getAllProfiles(fetchContext, page, filterObject);
          }
        } catch (err) {
          setNotify({
            isOpen: true,
            message: `There is some issue, please try again: ${err.message}`,
            type: "error",
          });
        }
      } else {
        if (clearChargingProfile(singleloaddata)) {
          const path = "/setChargingProfileSingleStack";
          const updated_payload = {
            connector_id: payload?.connector_id,
            charger_id: payload?.charger_id,
            start_date: `${payload?.start_date.split("T")[0]}T${
              payload?.start_time.split("T")[1].split(".")[0]
            }.000Z`,
            end_date: `${payload?.end_date.split("T")[0]}T${
              payload?.end_time.split("T")[1].split(".")[0]
            }.000Z`,
            start_time: `${payload?.start_time?.split(".")[0]}.000Z`,
            end_time: `${payload?.end_time?.split(".")[0]}.000Z`,
            limit: payload?.limit,
            charging_rate_unit: payload?.charging_rate_unit,
          };

          try {
            const { data } = await socketFetch.post(path, updated_payload);
            if (data?.message) {
              setLoaded(false);
              // getAllProfiles(fetchContext, page, filterObject);
              setNotify({
                isOpen: true,
                message: data.message,
                type: "success",
              });
              setOptions([]);

              setValues({
                connector_id: null,
                charger_id: null,
                start_date: null,
                end_date: null,
                start_time: null,
                end_time: null,
                limit: 0,
                charging_rate_unit: "A",
              });
              setFilterObject({ charger_id: null });
              // getAllProfiles(fetchContext, page, filterObject);
            }
          } catch (err) {
            setNotify({
              isOpen: true,
              message: `There is some issue, please try again: ${err.message}`,
              type: "error",
            });
          }
        }
      }
    } else {
      console.log("the err");
    }
  };

  const clearChargingProfile = async (payload) => {
    const path = "/clearChargingProfile";
    const updated_payload = {
      connector_id: payload?.connector_id,
      charger_id: payload?.charger_id,
      charging_rate_unit: payload?.charging_rate_unit,
      charging_profile_id: payload?.charging_profile_id
        ? payload?.charging_profile_id
        : "",
    };

    try {
      const { data } = await socketFetch.post(path, updated_payload);
      if (data?.message) {
        try {
          const { data } = await fetchContext.authAxios.post(
            `/delete-charging-profile`,
            {
              charging_profile_id: payload?.charging_profile_id,
            }
          );
          setOptions([]);

          setValues({
            connector_id: null,
            charger_id: null,
            start_date: null,
            end_date: null,
            start_time: null,
            end_time: null,
            limit: 0,
            charging_rate_unit: "A",
          });
          setFilterObject({ charger_id: null });
          // getAllProfiles(fetchContext, page, filterObject);
        } catch (err) {
          setNotify({
            isOpen: true,
            message: data.message,
            type: "error",
          });
        }
        if (!popup) {
          setNotify({
            isOpen: true,
            message: data.message,
            type: "success",
          });
        }
      }
    } catch (err) {
      // if (!popup) {
      setNotify({
        isOpen: true,
        message: `There is some issue, please try again: ${err.message}`,
        type: "error",
      });
      // }
    }
  };

  const handlePeriodChange = (event) => {
    let { name, value } = event.target;
    if (name === "limit") {
      if (value && value > 120) {
        value = 120;
      }
      if (value < 0) {
        value = 0;
      }
      setValues({ ...values, limit: value });
    }
  };

  const handleSiteEVSE = (event) => {
    setSiteEvse(event.target.value);
  };

  useEffect(() => {
    if(values.charger_id === ''){
      setOptions([])
    }
    setPayload({
      connector_id: values.connector_id,
      charger_id: values.charger_id ? values.charger_id.id : null,
      start_date: formatTimestampToISO(values.start_date),
      end_date: formatTimestampToISO(values.end_date),
      start_time: formatTimestampToISO(values.start_time),
      end_time: formatTimestampToISO(values.end_time),
      limit: parseInt(values.limit),
      charging_rate_unit: "A",
    });
  }, [values]);

  useEffect(() => {
    const currentDate = new Date();
    const startDate = values?.start_date ? new Date(values?.start_date) : "";
    let startDateOnly;
    if (values?.start_date) {
      startDateOnly = new Date(
        startDate?.getFullYear(),
        startDate?.getMonth(),
        startDate?.getDate()
      );
    }
    
    // Function to format date to MM-dd-yyyy
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0'); // Get day and ensure two digits
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month and ensure two digits
      const year = date.getFullYear(); // Get year
      return `${month}-${day}-${year}`; // Return in MM-dd-yyyy format
    };
    
    // Convert currentDate and startDateOnly to MM-dd-yyyy strings
    const currentDateOnly = formatDate(currentDate);
    const formattedStartDateOnly = startDateOnly ? formatDate(startDateOnly) : "";
    
    if (currentDateOnly > startDateOnly && values?.start_date !== undefined) {
      setstartdateCompareError(true);
    } else {
      setstartdateCompareError(false);
      if (new Date(values.start_date) > new Date(values.end_date)) {
        setDateCompareError(true);
      } else {
        setDateCompareError(false);
      }
      if (new Date(values.start_time) > new Date(values.end_time)) {
        setTimeCompareError(true);
      } else {
        setTimeCompareError(false);
      }
    }
  }, [values]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <div className={classes.pageTitle}>
            {/* <Typography variant="h5" component="div"> */}
            Single Stack Load Management
            {/* </Typography> */}
          </div>
        </Toolbar>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setChargingProfile(payload);
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            {/* <Grid item xs={12} >
              <SiteEVSERadioGroup
                name="userRole"
                value={siteEvse}
                onChange={handleSiteEVSE}
              />
            </Grid> */}
            {siteEvse === "1" ? (
              <Grid item xs={isDesktop ? 4 : 12}>
                <SitesSelect
                  value={values.site_id}
                  error={errors.site_id}
                  required={true}
                  disabled={true}
                  onChange={handleInputChange}
                  // style={{ width: "80%" }}
                />
              </Grid>
            ) : (
              <Grid item xs={isDesktop ? 4 : 12} style={{ marginTop: "6px" }}>
                <ChargingStationSelect
                  value={values.charger_id}
                  error={errors.charger_id}
                  required={true}
                  onChange={(e) => {
                    setValues({ ...values, charger_id: e });
                    // values.charger_id = e
                  }}
                  // style={{ width: "90%" }}
                />
              </Grid>
            )}
            <Grid item xs={isDesktop ? 3 : 12}>
              <Controls.Select
                label="Connector ID"
                options={options}
                required={true}
                name="connector_id"
                value={connector === 1 ? options[0]?.name : values.connector_id}
                onChange={handleInputChange}
              ></Controls.Select>
            </Grid>
            <Grid item xs={12}>
              &nbsp;
            </Grid>

            <Grid item xs={isDesktop ? 4 : 12}>
              <Controls.DatePicker
                name="start_date"
                label="From Date"
                value={values.start_date ? values.start_date : null}
                onChange={handleInputChange}
                error={errors.start_date}
                required={true}
                //fullWidth
                style={{ width: "98%" }}
              />
              {startdateCompareError && (
                <Grid item xs={12}>
                  <p style={{ color: "red" }}>
                    *The start date must be current and future date
                  </p>
                </Grid>
              )}
            </Grid>

            <Grid item xs={isDesktop ? 4 : 12}>
              <Controls.DatePicker
                name="end_date"
                label="To Date"
                value={values.end_date ? values.end_date : null}
                onChange={handleInputChange}
                error={errors.end_date}
                required={true}
                //fullWidth
                style={{ width: "98%" }}
              />
            </Grid>
            {isDesktop && (
              <Grid item xs={4}>
                &nbsp;
              </Grid>
            )}
            {isDesktop && (
              <Grid item xs={4}>
                &nbsp;
              </Grid>
            )}
            {isDesktop && (
              <Grid item xs={isDesktop ? 4 : 12}>
                {dateCompareError ? (
                  <p style={{ color: "red" }}>
                    *The start date must be before end date
                  </p>
                ) : (
                  ""
                )}
              </Grid>
            )}
            {isDesktop && (
              <Grid item xs={12}>
                &nbsp;
              </Grid>
            )}
            <Grid item xs={isDesktop ? 4 : 12}>
              <Controls.Input
                name="limit"
                label="Limit"
                required={true}
                type="number"
                max={1000}
                value={values.limit}
                onChange={(e) => handlePeriodChange(e)}
                // style={{ width: "80%" }}
              />
            </Grid>
            <Grid item xs={isDesktop ? 4 : 12}>
              <Controls.TimePicker
                name="start_time"
                label="Start Time"
                value={values.start_time ? values.start_time : null}
                onChange={handleInputChange}
                required={true}
                //fullWidth
                style={{ width: "98%" }}
              />
            </Grid>
            <Grid item xs={isDesktop ? 4 : 12}>
              <Controls.TimePicker
                name="end_time"
                label="End Time"
                //minDate={minDate}
                value={values.end_time ? values.end_time : null}
                required={true}
                onChange={handleInputChange}
                style={{ width: "98%" }}
              />
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={4}>
              &nbsp;
            </Grid>
            <Grid item xs={4}>
              {timeCompareError ? (
                <p style={{ color: "red" }}>
                  *The start time must be before end time
                </p>
              ) : (
                ""
              )}
            </Grid>
            {popup && (
              <p style={{ color: "#a31112" }}>
                {/* The Charging Profile has already been set against this charger,
                please clear the profile first in order to set it again. */}
                Charging profile is already set. Clear it first, or the new
                profile will overwrite the existing one.
              </p>
            )}

            <Grid item xs={12}>
              <div>
                <Controls.Button type="submit" text="Submit" />
                <Controls.Button
                  text="Clear"
                  color="default"
                  onClick={(e) => {
                    setOptions([]);
                    setstartdateCompareError(false);
                    e.preventDefault();
                    setDateCompareError(false);
                    setValues({
                      connector_id: null,
                      charger_id: null,
                      start_date: null,
                      end_date: null,
                      start_time: null,
                      end_time: null,
                      limit: 0,
                      charging_rate_unit: "A",
                    });
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Form>

        <TblContainer>
          <TblHead />
          <TableBody>
            {loaded ? (
              profiles.map((item) => (
                <TableRow>
                  <TableCell>{item.charging_profile_id}</TableCell>
                  <TableCell>{item.charger_id}</TableCell>
                  <TableCell>{item.charging_schedule_period}</TableCell>
                  {!fetchContext.isView && (
                    <TableCell class="text-nowrap">
                      <Confirm
                        id={item.charging_profile_id}
                        title={"Are you sure to clear this profile?"}
                        subTitle={"You can't undo this operation"}
                        onConfirmClick={() => {
                          clearChargingProfile({
                            charger_id: item.charger_id,
                            charging_profile_id: item.charging_profile_id,
                            connector_id: item.connector_id
                              ? item.connector_id
                              : 1,
                          });
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <Loader />
            )}
          </TableBody>
        </TblContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Typography spacing={2} style={{ color: "#8a0a0b" }} component="div">
            Total Records Found : {totalRecord}
          </Typography>
          {totalRecord > 0 ? (
            <Stack direction={"row-reverse"} spacing={2}>
              <Pagination
                style={{ margin: "0 10px 0 0" }}
                count={counts}
                page={page}
                onChange={handleChange}
                variant="outlined"
                color="secondary"
                siblingCount={0}
                boundaryCount={1}
                //shape="rounded/circular"
              />
            </Stack>
          ) : (
            <div></div>
          )}
        </div>
      </Paper>
    </>
  );
};

export default LoadSharingSingleStack;
