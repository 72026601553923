import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FormLabel, Grid, Paper, TableBody, TableCell, TableRow, Toolbar, Typography, makeStyles } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../components/useForm";
import useTable from "../../components/useTable";
import { FetchContext } from "../../context/FetchContext";
import Notification from "../../components/Notification";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Loader from "../../components/Loader";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ConfirmDialog from "../../components/ConfirmDialog";
import Popup from "../../components/Popup";
import CreateModel from "./CreateModel";
import { useIsDesktop } from "../../context/DisplayContext";

const initialFormValues = {
  name: "",
  manufacturer_id: "",
};

const useStyles = makeStyles((theme) => ({
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    fontFamily: "Aldrich",
    color: theme.palette.secondary.main,
    // left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  searchIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  headerContainer: {
    // padding: "18px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  filterContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "end",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
  },
  iconAlignment: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
  datePicker: {
    display: "inline-block",
    textAlign: "start",
  },
}));
export default function LabTabs() {
  const fetchContext = useContext(FetchContext);
  const [manufacturers, setManufacturers] = useState([]); // Ensure it's initialized as an empty array

  const [page, setPage] = useState(1);
  const [counts, setCounts] = useState(1);
  const [value, setValue] = React.useState("1");
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const { isDesktop } = useIsDesktop();
  const [totalRecord, setTotalRecord] = useState(0);

  // const [editFormData, setEditFormData] = useState("");
  const [editFormData, setEditFormData] = useState({
    id: "",
    name: "",
    manufacturer_id: "",
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handle2Close = () => {
    setOpenPopup2(false);
  }

  const handleClose = () => {
    setOpenPopup(false);
  }

  let headCells = [
    { id: "name", label: "Name" },
    { id: "manufacturer", label: "Manufacturer" },
  ];
  if (!fetchContext.isView) {
    headCells.push({ id: "actions", label: "", disableSorting: true });
  }
  const { TblContainer, TblHead } = useTable(headCells);

  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  // const { values, handleInputChange ,resetForm } = useForm(initialFormValues);
  const { values, handleInputChange, resetForm } = useForm({ name: "" });

  const handleChangePage = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchManufacturers(); // Fetch manufacturers when the component mounts
  }, []);

  const fetchManufacturers = async () => {
    try {
      const response = await fetchContext.authAxios.get("/select-manufacturers");
      if (response.status === 200 && response.data.data) {
        // Verify the structure of the data received from the API
        setManufacturers(response.data.data); // Update the state with the fetched data
      }
    } catch (error) {
      console.error("Error fetching manufacturers:", error);
    }
  };

  const [models, setModels] = useState("");
  useEffect(() => {
    getAllmodels(fetchContext, page);
  }, [fetchContext.authAxios, page]);

  const getAllmodels = async (fetchContext, page) => {
    try {
      const { data } = await fetchContext.authAxios.get(`/charger-models?page=${page}`);
      setModels(data.data);
      setTotalRecord(data.totalItems);

      if (data.totalItems && data.totalItems > 20) {
        let count = Math.ceil(data.totalItems / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }
      setLoading(true); // Hide loader after data is fetched
    } catch (err) {
    }
  };

  const handleChangePagemodel = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoading(false);
    }
  };
  const fetchLatestModels = () => {
    fetchContext.authAxios
      .get("/charger-models")
      .then((response) => {
        if (response.status === 200 && response?.data.data) {
          setModels(response.data.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching manufacturers:", error);
      });
  };

  const handleSubmitmodel = async (event) => {
    setOpenPopup2(true);

    event.preventDefault();
    try {
      const response = await fetchContext.authAxios.post("/charger-models", {
        name: values.name,
        manufacturer_id: values.manufacturerId,
      });
      if (response.status === 200 && response?.data?.status) {
        setNotify({
          isOpen: true,
          message: "Charger model added successfully",
          type: "success",
        });
        setOpenPopup2(false);
        // fetchLatestModels();
        getAllmodels(fetchContext, 1);
        resetForm(); // Clear form fields after successful submission
      } else {
        setNotify({
          isOpen: true,
          message: response?.data?.errors?.name[0] || "Something went wrong",

          type: "error",
        });
      }
    } catch (error) {
      console.error("Error adding charger model:", error);
      setNotify({
        isOpen: true,
        message: "There was an error adding the charger model. Please try again.",
        type: "error",
      });
    }
  };
  const handleEditClick2 = async (id) => {
    setOpenPopup2(true);
  };

  const handleDelete = async (id) => {
    fetchContext.authAxios
      .delete(`/charger-models/${id}`)
      .then(({ data }) => {
        if (data.status) {
          getAllmodels(fetchContext, page);
          setLoading(false);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        } else {
          if (data.data) {
            setNotify({
              isOpen: true,
              message: `Model  can't be deleted as: ${data.data}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Model  can't be deleted as: ${err}`,
          type: "error",
        });
      });
  };
  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    handleDelete(id);
    // setNotify({
    //     isOpen: true,
    //     message: 'Deleted Successfully',
    //     type: 'error'
    // })
  };
  const handleEditClick = async (id) => {
    setOpenPopup(true);
    try {
      const response = await fetchContext.authAxios.get(`/charger-models/${id}`);
      if (response.status === 200 && response?.data.data) {
        const { id, name, manufacturer_id } = response.data.data;
        setEditFormData({ id, name, manufacturer_id });
      }
    } catch (error) {
      console.error("Error fetching manufacturer data:", error);
    }
  };

  const handleEditSubmit = (event, id) => {
    event.preventDefault();
    fetchContext.authAxios
      .put(`/charger-models/${editFormData.id}`, {
        id: editFormData.id,
        name: editFormData.name,
        manufacturer_id: editFormData.manufacturer_id,
      })
      .then((response) => {
        if (response.status === 200 && response?.data?.status) {
          setNotify({
            isOpen: true,
            message: "Model updated successfully",
            type: "success",
          });
          // fetchLatestModels();
          getAllmodels(fetchContext, 1);
          setOpenPopup(false); // Close the popup after successful submission
        } else {
          setNotify({
            isOpen: true,
            message: response?.data?.errors?.name[0] || "Something went wrong",

            type: "error",
          });
        }
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            "There was an error updating the manufacturer. Please try again.",
          type: "error",
        });
      });
  };
  return (
    <>
      {/* <Toolbar>
        <div className={classes.pageTitle}>
          <Typography variant="h5" component="div">Charger Models</Typography>
        </div>

        <Controls.Button
          style={{ color: "#a31112", border: "1px solid #a31112" }}
          text="Add New"
          variant="outlined"
          startIcon={<AddIcon />}
          className={classes.newButton}

          onClick={() => handleEditClick2()}
        />
      </Toolbar> */}
      <div className={classes.headerContainer}>
        <div className={classes.pageTitle}>Charger Models</div>
        <div className={classes.filterContainer}>
          {/* <div className={classes.iconAlignment}> */}
          {fetchContext.isAdmin && (
            <Controls.Button
              style={{ color: "#a31112", border: "1px solid #a31112" }}
              text="Add New"
              variant="outlined"
              isPaddingTop={false}
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => handleEditClick2()}
            />
          )}
          {/* </div> */}
        </div>
      </div>
      <Notification notify={notify} setNotify={setNotify} />

      <Popup title="Add Charger Model" openPopup={openPopup2} setOpenPopup={setOpenPopup2}>
        <Form onSubmit={handleSubmitmodel}>
          <Grid container spacing={2}>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Input name="name" label="Model Name" value={values.name} onChange={handleInputChange} required />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Select
                name="manufacturerId"
                label="Manufacturer"
                value={values.manufacturerId}
                onChange={handleInputChange}
                options={manufacturers.map((manufacturer) => ({
                  id: manufacturer.id,
                  name: manufacturer.name, // Assuming the manufacturer object has a 'name' property
                }))}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Button type="submit" text="Add Charger Model" />
              <Controls.Button text="Cancel" color="default" onClick={handle2Close} />
            </Grid>
          </Grid>
        </Form>
      </Popup>

      <div>
        <TblContainer>
          <TblHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id}>{headCell.label}</TableCell>
              ))}
            </TableRow>
          </TblHead>
          {loading ? (
            <TableBody>
              {models.map((model) => (
                <TableRow key={model.id}>
                  <TableCell>{model.name}</TableCell>

                  <TableCell> {model.manufacturer ? model.manufacturer.name : ""}</TableCell>

                  {fetchContext.isAdmin && (
                    <TableCell className="text-nowrap">
                      <Controls.ActionButton color="secondary" onClick={() => handleEditClick(model.id)}>
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure to delete this record?",
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(model.id);
                            },
                          });
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </Controls.ActionButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>

        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </div>
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />

      <Popup title="Edit Charger Model" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <Form onSubmit={handleEditSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Input
                name="name"
                label=" Model Name"
                value={editFormData.name || ""}
                onChange={(e) => setEditFormData({ ...editFormData, name: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              <Controls.Select
                name="manufacturer_id"
                label="Manufacturer"
                value={editFormData.manufacturer_id}
                onChange={(e) => setEditFormData({ ...editFormData, manufacturer_id: e.target.value })}
                options={manufacturers.map((manufacturer) => ({
                  id: manufacturer.id,
                  name: manufacturer.name,
                }))}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Button type="submit" text="Update Charger Model" />
              <Controls.Button text="Cancel" color="default" onClick={handleClose} />
            </Grid>
          </Grid>
        </Form>
      </Popup>
    </>
  );
}
