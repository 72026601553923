import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import ChargingStationSelect from "./ChargingStationSelect";
import SiteSelect from "./SiteSelect";
import AuthTypeSelect from "./AuthTypeSelect";
import { FetchContext } from "../../context/FetchContext";
import { id } from "date-fns/locale";
import { useIsDesktop } from "../../context/DisplayContext";

const RfidForm = (props) => {
  const { addOrEdit, recordForEdit, closeForm } = props;
  const initialFValues = {
    id: 0,
    name: "",
    site_id: [],
    charging_station_id: [],
    type: recordForEdit !== null ? recordForEdit.type : "",
    isSelectAll: false,
  };
  const fetchContext = useContext(FetchContext);
  const { isDesktop } = useIsDesktop();

  const [selected, setSelected] = useState(recordForEdit !== null ? recordForEdit.type : "");
  const [selectedLable, setSelectedLable] = useState("Authentication Tag");
  const [disabledChargingStation, setDisabledChargingStation] = useState(false);
  const [disabledSite, setDisabledSite] = useState(false);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [dropdownChargingValues, setDropdownChargingValues] = useState([]);
  const [optionValues, setOptionValues] = useState([]);
  const [optionValues1, setOptionValues1] = useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if (!fetchContext?.isAdmin) {
      temp.name = !(fieldValues?.name ? fieldValues.name : values?.name).replace(/\s/g, "").toLowerCase().includes("redesupport")
        ? ""
        : "You can not use Red E Support in the name";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    const getAllChargingStations = async () => {
      try {
        const { data } = await fetchContext.authAxios.get("/select-sites");
        setDropdownValues(data.data);
        // setLoaded(true);
      } catch (err) {
        console.log("the err", err);
      }
    };

    getAllChargingStations(fetchContext);
  }, [fetchContext]);

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
        name: recordForEdit.name,
        type: recordForEdit.type,
        id: recordForEdit.id,
        token: recordForEdit.token,
        site_id: recordForEdit?.sites ? recordForEdit?.sites : [],
        charging_station_id: recordForEdit?.charging_stations ? recordForEdit?.charging_stations : [],
      });
    }
  }, [recordForEdit, setValues]);

  const changeSelectOptionHandler = (event) => {
    values.type = event.target.value;
    setSelected(event.target.value);
    setSelectedLable(event.target.value);
  };

  function filterObjectsWithSingleOccurrence(array) {
    const objectCount = {};
    for (const obj of array) {
      if (objectCount[obj.id]) {
        objectCount[obj.id]++;
      } else {
        objectCount[obj.id] = 1;
      }
    }
    const filteredArray = array.filter((obj) => objectCount[obj.id] === 1);
    return filteredArray;
  }

  const handleLevel = (event) => {
    const { value, name, key } = event.target;
    const filteredArray = filterObjectsWithSingleOccurrence(value);
    if (event.target.name === "site_id") {
      values.charging_station_id = [];
      setDisabledChargingStation(true);
      let filteredData = filteredArray?.filter((it) => it.name === "Select All");
      if (filteredData?.length > 0) {
        if (values?.isSelectAll) {
          setValues({ ...values, site_id: [], isSelectAll: false });
        } else {
          setValues({ ...values, site_id: filteredData[0]?.data, isSelectAll: true });
        }
      } else {
        setValues({ ...values, site_id: filteredArray?.filter((it) => it.name !== "Select All"), isSelectAll: false });
      }
    } else {
      setDisabledSite(true);
      values.site_id = [];
      let filteredData = filteredArray?.filter((it) => it.name === "Select All");
      if (filteredData?.length > 0) {
        if (values?.isSelectAll) {
          setValues({ ...values, charging_station_id: [], isSelectAll: false });
        } else {
          setValues({ ...values, charging_station_id: filteredData[0]?.data, isSelectAll: true });
        }
      } else {
        setValues({ ...values, charging_station_id: filteredArray?.filter((it) => it.name !== "Select All"), isSelectAll: false });
      }
    }
  };

  useEffect(() => {
    const getAllChargingStations = async () => {
      try {
        const { data } = await fetchContext.authAxios.get("/select-sites");
        setDropdownValues(data.data);
        const optionSites = [];
        for (const item of data.data) {
          const optObj = { value: item.id, label: item.name };
          optionSites.push(optObj);
        }
        setOptionValues(optionSites);
      } catch (err) {
        console.log("the err", err);
      }
    };
    getAllChargingStations(fetchContext);
  }, [fetchContext]);

  useEffect(() => {
    const getAllChargingStations = async () => {
      try {
        const { data } = await fetchContext.authAxios.get("/select-charging-stations");
        setDropdownValues(data.data);
        const optionSites = [];
        for (const item of data.data) {
          const optObj = {
            value: item.id,
            label: `${item.name} (${item?.ocpp_cbid})`,
          };
          optionSites.push(optObj);
        }
        setOptionValues1(optionSites);
      } catch (err) {
        console.log("the err", err);
      }
    };
    getAllChargingStations(fetchContext);
  }, [fetchContext]);

  const getOptionSiteLabel = (option) => option.label;
  const getOptionDisabled = (option) => option.value === 1;
  const handleToggleOption = (selectedOptions) => {
    setValues({ ...values, site_id: selectedOptions, charging_station_id: [] });
    if (selectedOptions?.length > 0) {
      // setValues({ ...values, charging_station_id: [] });
      setDisabledChargingStation(true);
    } else {
      setDisabledChargingStation(false);
    }
  };
  const handleClearOptions = () => {
    setDisabledChargingStation(false);
    setValues({ ...values, site_id: [] });
  };
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setDisabledChargingStation(true);
      setValues({ ...values, site_id: optionValues });
    } else {
      setDisabledChargingStation(false);
      handleClearOptions();
    }
  };

  const getOptionSiteLabel1 = (option) => option.label;
  const getOptionDisabled1 = (option) => {
    return option.value === 1;
  };

  const handleToggleOption1 = (selectedOptions) => {
    setValues({ ...values, charging_station_id: selectedOptions, site_id: [] });
    if (selectedOptions?.length > 0) {
      setDisabledSite(true);
      // setValues({ ...values, site_id: [] });
    } else {
      setDisabledSite(false);
    }
  };

  const handleClearOptions1 = () => {
    setDisabledSite(true);
    setValues({ ...values, charging_station_id: [] });
  };

  const handleSelectAll1 = (isSelected) => {
    if (isSelected) {
      setDisabledSite(true);
      setValues({ ...values, charging_station_id: optionValues1 });
    } else {
      handleClearOptions1();
    }
  };

  return (
    <Form onSubmit={handleSubmit} >
      <Grid container>
        <Grid item xs={12}>
          <Controls.Input name="name" label="Name" required value={values.name} onChange={handleInputChange} error={errors.name} fullWidth style={{ width: "97.5%" }} />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <AuthTypeSelect label="Authentication Type" name="type" value={values.type} error={errors.type} required onChange={changeSelectOptionHandler} />
        </Grid>
        <Grid item xs={isDesktop ? 6 : 12}>
          <Controls.Input
            name="token"
            label={selectedLable}
            required
            value={values.token}
            onChange={handleInputChange}
            error={errors.token}
            fullWidth
            style={{ width: "95%" }}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <SiteSelect
            value={values.site_id}
            error={errors.site_id}
            disabled={disabledSite}
            recordForEdit={recordForEdit}
            isMultiple={true}
            onChange={handleLevel}
            style={{ width: "95%" }}
            setDropdownValues={setDropdownValues}
            dropdownValues={dropdownValues}
          /> */}
          <Controls.MultiSelect
            items={optionValues}
            getOptionLabel={getOptionSiteLabel}
            getOptionDisabled={getOptionDisabled}
            disable={disabledSite}
            selectedValues={values?.site_id}
            label={"Sites"}
            placeholder={"Placeholder for Sites"}
            selectAllLabel="Select all"
            onToggleOption={handleToggleOption}
            onClearOptions={handleClearOptions}
            onSelectAll={handleSelectAll}
          />
        </Grid>

        <Grid item xs={12}>
          {/* <ChargingStationSelect
            value={values.charging_station_id}
            error={errors.charging_station_id}
            recordForEdit={recordForEdit}
            isMultiple={true}
            disabled={disabledChargingStation}
            onChange={handleLevel}
            style={{ width: "95%" }}
            setDropdownChargingValues={setDropdownChargingValues}
            dropdownChargingValues={dropdownChargingValues}
          /> */}
          <Controls.MultiSelect
            items={optionValues1}
            disabled={true}
            getOptionLabel={getOptionSiteLabel1}
            getOptionDisabled={getOptionDisabled1}
            disable={disabledChargingStation}
            selectedValues={values?.charging_station_id}
            label={"Charging Station"}
            placeholder={"Placeholder for Charging Station"}
            selectAllLabel="Select all"
            onToggleOption={handleToggleOption1}
            onClearOptions={handleClearOptions1}
            onSelectAll={handleSelectAll1}
          />
        </Grid>

        <Grid item xs={12}>
          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button text="Cancel" color="default" onClick={closeForm} />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default RfidForm;
