import React, { useContext, useEffect, useState } from "react";
//import { FetchContext } from '../../context/FetchContext';
import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography, makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Form, useForm } from "../../components/useForm";
import { FetchContext } from "../../context/FetchContext";
import Loader from "./../../components/Loader";
import Controls from "./../../components/controls/Controls";
import useTable from "./../../components/useTable";
import EnerySubGraph from "./EnerySubGraph";
import ExportDataEnergySub from "./ExportDataEnergySub";
import { useIsDesktop } from "../../context/DisplayContext";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    // position: "absolute",
    // left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  newButton: {
    // position: "absolute",
    // right: "0px",
    //width: '75%'
  },
  formAlignment: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },

  exportIcon: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
}));

const EnergySub = () => {
  const [data, setData] = useState();
  const [maxValue, setMaxValue] = useState(250);
  const dateStr = DateTime.now();
  const selectedMonth = dateStr.toFormat(`01-${"MM-yyyy"}`);
  const classes = useStyles();
  // const [records, setRecords] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const fetchContext = useContext(FetchContext);
  const currentDate = new Date();
  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() - 30);
  const [startDate, setStartDate] = useState(futureDate);
  const [endDate, setEndDate] = useState(new Date());
  const { isDesktop } = useIsDesktop();

  // const start = DateTime.now().minus({ days: 30 });
  // const end = DateTime.now();

  const initialFValues = {
    from_date: startDate,
    to_date: endDate,
  };

  const formatDateToDDMMYYYY = (isoDateString) => {
    const date = new Date(isoDateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

const getAllTransactions = async (fetchContext, payload) => {
  try {
    let updatedPayload = {
      range: payload?.range,
      to_date: formatDateToDDMMYYYY(payload?.to_date),
      from_date: formatDateToDDMMYYYY(payload?.from_date),
    };

    let currentPage = 1;
    let totalPages = 1;
    let allTransactions = [];

    do {
      // Add the page parameter to the payload for pagination
      const paginatedPayload = { ...updatedPayload, page: currentPage };

      // Fetch paginated data
      const { data } = await fetchContext.authAxios.post(
        `/get-energy-revenue-for-admin?page=${currentPage}`,
        paginatedPayload
      );

      // Accumulate transactions
      allTransactions.push(...data.data);

      // Update totalPages and increment currentPage
      totalPages = data.totalPages;
      currentPage++;
    } while (currentPage <= totalPages);

    // Set the loaded state and update transactions
    setLoaded(true);
    setTransactions(allTransactions); // Update state with all data
    handleFilterData(allTransactions, {
      to_date: payload.to_date,
      from_date: payload.from_date,
    });
  } catch (err) {
    console.log("Error fetching transactions:", err);
  }
};

  const handleFilterData = (data, valuesdd) => {
    console.log("data", data);
    
    const dailyData = {};
    let transactions = data;
    if (!transactions) {
      return;
    }

    let startDate = DateTime.fromJSDate(valuesdd.from_date);
    let endDate = DateTime.fromJSDate(valuesdd.to_date);
    let datetime = startDate.startOf("day");

    while (datetime < endDate.endOf("day")) {
      dailyData[datetime.toISO()] = {
        datetime,
        count: 0,
        usage: 0,
      };
      datetime = datetime.plus({ days: 1 });
    }

    transactions.forEach((transaction) => {
      // if (!transaction.meter_end || !transaction.meter_start) {
      //   return;
      // }
      if (transaction?.meter_diff / 1000 > 0) {
        const key = DateTime.fromSQL(transaction.graph_date).startOf("day").toISO();
        if (key && dailyData[key]) {
          dailyData[key].count++;
          dailyData[key].usage += transaction?.meter_diff / 1000;
        }
      }
    });

    let updatedData = Object.values(dailyData).reduce((acc, { count, datetime, usage }) => {
      const dt = DateTime.fromMillis(datetime.ts);
      const month = dt.monthLong;
      return [
        ...acc,
        datetime !== undefined && {
          usage: usage.toFixed(2),
          average: count ? getRound(usage, count) : 0,
          count,
          //date: datetime.toLocaleString(DateTime.DATE_SHORT),
          date: datetime,
          month,
        },
      ];
    }, []);
    setData(updatedData);
  };

  useEffect(() => {
    getAllTransactions(fetchContext, { range: true, from_date: values.from_date, to_date: values.to_date });
  }, [fetchContext]);

  const headCells = [{ id: "", label: "" }];
  const { TblContainer } = useTable(headCells);

  const getRound = (value1, value2) => {
    let response = 0;
    if (value2 !== undefined) response = (value1 / value2).toFixed(2);
    else response = value1 / value2;

    return response;
  };

  const getTotal = (data) => {
    let allTotal = 0;
    // let allCount = 0;
    let number_of_days = data?.length;
    let final_average = 0;
    if (data) {
      for (const d of data) {
        if (Number(d.usage) > 0) {
          allTotal = allTotal + parseFloat(d.usage);
          // allCount = allCount + parseFloat(d.count);
        }
      }
    }

    final_average = allTotal > 0 ? (allTotal / number_of_days).toFixed(2) : "0.00";
    return {
      allTotal: allTotal.toFixed(2),
      // allCount: allCount.toFixed(2),
      average: final_average,
    };
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    //if ('name' in fieldValues)
    //temp.name = fieldValues.name ? "" : "This field is required."
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    setErrors,
    errors,
    handleInputChange,
    //resetForm
  } = useForm(initialFValues, true, validate);

  useEffect(() => {
    if (data) {
      const maxUsage = data.reduce((max, obj) => {
        const usage = parseFloat(obj.usage);
        return usage > max ? usage : max;
      }, 0);

      const nextMultiple = Math.ceil(maxUsage / 100) * 100;
      setMaxValue(nextMultiple);
    }
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStartDate(DateTime.fromJSDate(values.from_date));
    setEndDate(DateTime.fromJSDate(values.to_date));
    getAllTransactions(fetchContext, {
      range: true,
      from_date: values.from_date,
      to_date: values.to_date,
    });
  };

  const onClear = () => {
    getAllTransactions(fetchContext, { range: true, from_date: futureDate, to_date: new Date() });
    setValues({
      from_date: futureDate,
      to_date: new Date(),
    });
  };

  function formatNumberWithLeadingZero(number) {
    if (number >= 1 && number <= 9) {
      return `0${number}`;
    } else {
      return `${number}`;
    }
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-light p-2">
          <p className="py-0 my-0">{`${payload[0].payload.month} ${label}`}</p>
          <p className="py-0 my-0 text-danger">{`Total (kWh) : ${payload[0].value}`}</p>
          <p className="py-0 my-0" style={{ color: "#01b2aa" }}>{`Average (kWh) : ${payload[1].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar> */}
        <div className={classes.formAlignment}>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Energy Graph
            </Typography>
          </div>

          <div className={classes.newButton}>
            <Form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
              <Grid container spacing={0}>
                <Grid item xs={isDesktop ? 6 : 12}>
                  <Controls.DatePicker
                    name="from_date"
                    label="From Date"
                    value={values.from_date}
                    onChange={handleInputChange}
                    error={errors.from_date}
                    style={{ width: "95%" }}
                  />
                </Grid>
                <Grid item xs={isDesktop ? 6 : 12}>
                  <Controls.DatePicker
                    name="to_date"
                    label="To Date"
                    value={values.to_date}
                    minDate={values.from_date}
                    onChange={handleInputChange}
                    error={errors.to_date}
                    style={{ width: "95%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <Controls.Button type="submit" text="Fetch" size="small" />
                    <Controls.Button text="Clear" color="default" size="small" onClick={onClear} />
                  </div>
                </Grid>
                {/* <Grid item xs={0}>
                  <div className={classes.exportIcon}>
                    <ExportDataEnergySub data={data} formatDateToDDMMYYYY={formatDateToDDMMYYYY} formatNumberWithLeadingZero={formatNumberWithLeadingZero} />
                  </div>
                </Grid> */}
              </Grid>
            </Form>
          </div>
        </div>
        {/* </Toolbar> */}
        <br />
        <br />
        <br />
        <div class="card mb-4" style={{ borderRadius: "10px" }}>
          <div class="card-body pb-5">
            <div class="card-header row" style={{ background: "#fff" }}>
              <div class="col-md-6">
                <strong class="card-title">Energy Usage </strong> <span class="card-title">(in kilowatt-hour)</span>{" "}
              </div>
              <div class="col-md-3 totalkwh">
                Total <span>{getTotal(data).allTotal}</span>{" "}
              </div>
              <div class="col-md-3 totalkwh">
                {" "}
                Average <span>{getTotal(data).average}</span>
              </div>
            </div>
            <div class="clearfix"></div>
            {/* <EnerySubGraph setData={setData} data={data} maxValue={maxValue} transactions={transactions} startDate={startDate} endDate={endDate} values={values}/> */}
            <Table>
              <TableRow>
                <TableCell>
                  <div className="responsiveCharts">
                    <ResponsiveContainer>
                      <LineChart
                        data={data?.map((dt) => {
                          return {
                            ...dt,
                            date: dt?.date?.c?.day,
                          };
                        })}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: -20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis dataKey="date" />
                        <YAxis domain={[0, maxValue]} tickCount={11} />
                        <Line type="monotone" dataKey="usage" name="Total (kWh)" stroke="#a31135" strokeWidth={1} dot={false} />

                        <Line type="monotone" dataKey="average" name="Average (kWh)" stroke="#01b2aa" strokeWidth={1} dot={false} />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend wrapperStyle={{ position: "relative" }} />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </TableCell>
              </TableRow>
            </Table>
          </div>
        </div>

        <TblContainer>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tblCellInside}>#</TableCell>
              <TableCell className={classes.tblCellInside}>Date</TableCell>
              <TableCell className={classes.tblCellInside}>Average (kWh)</TableCell>
            </TableRow>
          </TableHead>
          {loaded ? (
            <TableBody>
              {data?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{`${formatNumberWithLeadingZero(item?.date?.c?.month)}/${formatNumberWithLeadingZero(item?.date?.c?.day)}/${
                    item?.date?.c?.year
                  } `}</TableCell>
                  <TableCell>{Number(item.average).toFixed(2)} %</TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
      </Paper>
    </>
  );
};

export default EnergySub;
