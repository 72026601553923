import React, { useContext } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import SitesSelect  from './SitesSelect';
import SitesGroupSelect  from './SitesGroupSelect';
import TransactionTypeSelect from './TransactionTypeSelect';
import Divider from '@mui/material/Divider';
import TransactionStatusSelect from './TransactionStatusSelect';
import ChargerGroupSelect from './ChargerGroupSelect';
import { useIsDesktop } from '../../context/DisplayContext';
import ManufacturersSelect from '../ChargingStations/ManufacturersSelect';
import { FetchContext } from '../../context/FetchContext';
import SessionStartedBySelect from './SessionStartedBySelect';

const Filter = (props) => {
    const { onFilter, oncancle, filterObject } = props
    const { isDesktop } = useIsDesktop();
    const fetchContext = useContext(FetchContext);

    const initialFValues = {
      "rfid_name":filterObject.rfid_name,
      "transaction_status":filterObject.transaction_status,
      "session_started_by":filterObject.session_started_by,
      "site_id":filterObject.site_id,
      "site_group_id":filterObject.site_group_id,
      "ocpp_cbid":filterObject.ocpp_cbid,
      "max_energy":filterObject.max_energy,
      "min_energy":filterObject.min_energy,
      "session_id":filterObject.session_id,
      "from_date":filterObject.from_date,
      "to_date":filterObject.to_date,     
      "charger_group_id" : filterObject.charger_group_id
      //"export":filterObject.export 
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        //if ('name' in fieldValues)
            //temp.name = fieldValues.name ? "" : "This field is required."
         setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        //setValues,
        errors,
        setErrors,
        handleInputChange,
        //resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
          if(!values.rfid_name) { values.rfid_name = filterObject.rfid_name };
          if(!values.site_id) { values.site_id = filterObject.site_id }; 
          if(!values.site_group_id) { values.site_group_id = filterObject.site_group_id };         
          // if(!values.session_id) { values.session_id = filterObject.session_id };
          if(!values.transaction_status) { values.transaction_status = filterObject.transaction_status };
          if(!values.session_started_by) { values.session_started_by = filterObject.session_started_by };
          //if(values.rfid_name === "None") {values.rfid_name = null };
          onFilter(values);
        }
    }


    return (
      <Form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Divider style={{ width: "100%", color: "#808080" }} />
          <Grid item xs={isDesktop ? 2 : 12}>
            <Controls.Input
              name="ocpp_cbid"
              label="OCPP CBID"
              //required
              value={values.ocpp_cbid}
              onChange={handleInputChange}
              error={errors.ocpp_cbid}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 2 : 12}>
            <Controls.Input
              name="session_id"
              label="Session ID"
              //required
              value={values.session_id}
              onChange={handleInputChange}
              error={errors.session_id}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 2 : 12}>
            <Controls.Input
              name="min_energy"
              label="Energy Delivered >"
              //required
              type="number"
              value={values.min_energy}
              onChange={handleInputChange}
              error={errors.min_energy}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 2 : 12}>
            <Controls.Input
              name="max_energy"
              label="Energy Delivered <"
              //required
              type="number"
              value={values.max_energy}
              onChange={handleInputChange}
              error={errors.max_energy}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 2 : 12}>
            <Controls.DatePicker
              name="from_date"
              label="From Date"
              value={values.from_date}
              onChange={handleInputChange}
              error={errors.from_date}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 2 : 12}>
            <Controls.DatePicker
              name="to_date"
              label="To Date"
              minDate={values.from_date}
              value={values.to_date}
              onChange={handleInputChange}
              error={errors.to_date}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          {/* <Grid item xs={isDesktop ? 2 : 12}>
            <TransactionTypeSelect
              name="session_type"
              label="Session Types"
              value={values.session_type}
              error={errors.session_type}
              //required
              style={{ width: "95%" }}
              onChange={handleInputChange}
            />
          </Grid> */}
          <Grid item xs={isDesktop ? 2 : 12}>
            <Controls.Input
              name="rfid_name"
              label="RFID Name or Token"
              //required
              value={values.rfid_name}
              onChange={handleInputChange}
              error={errors.rfid_name}
              //fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 2 : 12}>
            <SitesSelect
              //label="Sites"
              //name="site_id"
              value={values.site_id}
              error={errors.site_id}
              //required
              style={{ width: "95%" }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={isDesktop ? 2 : 12}>
            <SitesGroupSelect
              //label="Sites"
              //name="site_id"
              value={values.site_group_id}
              error={errors.site_group_id}
              //required
              style={{ width: "95%" }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={isDesktop ? 2 : 12}>
            <TransactionStatusSelect
              label="Session Status"
              name="transaction_status"
              value={values.transaction_status}
              error={errors.transaction_status}
              //required
              style={{ width: "95%" }}
              onChange={handleInputChange}
            />
          </Grid>

          {/* <Grid item xs={isDesktop ? 2 : 12} >
                  <ChargerGroupSelect
                    label="Charger Group"
                    name="charger_group_id"
                    value={values.charger_group_id}  
                    error={errors.charger_group_id} 
                    //required
                    style={{ width: "95%" }}
                    onChange={handleInputChange}
                  />
                </Grid> */}
          {fetchContext.isAdmin && (
            <Grid item xs={isDesktop ? 2 : 12}>
              <ManufacturersSelect
                label="Charger Manufacturer"
                name="manufacturer_id"
                value={values.manufacturer_id}
                error={errors.manufacturer_id}
                required={false}
                filter={true}
                style={{ width: "95%" }}
                onChange={handleInputChange}
              />
            </Grid>
          )}
          {/* <Grid item xs={isDesktop ? 2 : 12} >
                  <SessionStartedBySelect
                    label="Session Started By"
                    name="session_started_by"
                    value={values.session_started_by}  
                    error={errors.session_started_by} 
                    //required
                    style={{ width: "95%" }}
                    onChange={handleInputChange}
                  />
                </Grid> */}
          <Grid item xs={isDesktop ? 2 : 12}>
            <div>
              <Controls.Button type="submit" text="Filter" size="small" />
              <Controls.Button
                text="Cancel"
                color="default"
                size="small"
                onClick={oncancle}
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    );
}

export default Filter;
